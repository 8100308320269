// src/functions/Median.js
import React, { useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  LabelList,
  ResponsiveContainer,
} from 'recharts';
import { BlockMath } from 'react-katex';
import 'katex/dist/katex.min.css';
import './Median.css';

const initialData = [
  { name: 'Value 1', value: 10 },
  { name: 'Value 2', value: 15 },
  { name: 'Value 3', value: 20 },
  { name: 'Value 4', value: 25 },
  { name: 'Value 5', value: 30 },
];

const calculateMedian = (data) => {
  if (data.length === 0) return 0;
  const sortedValues = data.map(item => item.value).sort((a, b) => a - b);
  const mid = Math.floor(sortedValues.length / 2);
  return sortedValues.length % 2 !== 0 ? sortedValues[mid] : (sortedValues[mid - 1] + sortedValues[mid]) / 2;
};

const MedianVisualization = ({ data, median }) => {
  // Similar to MeanVisualization, but adapted for median
  // ...
};

const StepByStep = ({ data, median }) => {
  // Step-by-step explanation for median calculation
  // ...
};

const Quiz = () => {
  // Quiz component for median
  // ...
};

const BestPractices = () => {
  // Best practices for median
  // ...
};

const Median = () => {
  const [data, setData] = useState(initialData);
  const [median, setMedian] = useState(calculateMedian(initialData));

  const addDataPoint = () => {
    // Logic to add a data point
    // ...
  };

  const removeDataPoint = () => {
    // Logic to remove a data point
    // ...
  };

  const resetData = () => {
    // Logic to reset data
    // ...
  };

  return (
    <div className="median-container">
      <h2 className="function-title">Median</h2>
      <p className="function-description">
        The median is the middle value in a sorted list of numbers. For an even number of values, it's the average of the two middle numbers.
      </p>

      <MedianVisualization data={data} median={median} />

      <div className="controls">
        <button onClick={addDataPoint}>Add Data Point</button>
        <button onClick={removeDataPoint}>Remove Data Point</button>
        <button onClick={resetData}>Reset Data</button>
      </div>

      <StepByStep data={data} median={median} />
      <Quiz />
      <BestPractices />

      <div className="formula-section">
        <h3>Mathematical Formula</h3>
        <BlockMath math="\text{Median} = \begin{cases} x_{\frac{n+1}{2}} & \text{if n is odd} \\ \frac{1}{2}(x_{\frac{n}{2}} + x_{\frac{n}{2}+1}) & \text{if n is even} \end{cases}" />
        <p>Where n is the number of values, and x_i represents the i-th value in the sorted dataset.</p>
      </div>

      <div className="io-explanation">
        <h3>Input and Output</h3>
        <p><strong>Input:</strong> A set of numerical values</p>
        <p><strong>Output:</strong> The middle value (or average of two middle values) in the sorted dataset</p>
      </div>

      <div className="real-world-application">
        <h3>Real-World Application</h3>
        <p>The median is used in various fields:</p>
        <ul>
          <li>In economics, to calculate median income</li>
          <li>In real estate, to determine median house prices</li>
          <li>In statistics, as a robust measure of central tendency</li>
          <li>In data analysis, to identify the central value in skewed distributions</li>
        </ul>
      </div>
    </div>
  );
};

export default Median;