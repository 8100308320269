// src/functions/Mean.js
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  LabelList,
  ResponsiveContainer,
} from 'recharts';
import { BlockMath, InlineMath } from 'react-katex';
import 'katex/dist/katex.min.css';
import {
  ArrowRight,
  ArrowLeft,
  Plus,
  Minus,
  RefreshCw,
  CheckCircle,
  XCircle,
  ChevronDown,
  ChevronRight,
  Info,
} from 'lucide-react';
import './Mean.css';

const initialData = [
  { name: 'Value 1', value: 10 },
  { name: 'Value 2', value: 15 },
  { name: 'Value 3', value: 20 },
  { name: 'Value 4', value: 25 },
  { name: 'Value 5', value: 30 },
];

const calculateMean = (data) => {
  if (data.length === 0) return 0;
  const total = data.reduce((sum, item) => sum + item.value, 0);
  return parseFloat((total / data.length).toFixed(2));
};

const MeanVisualization = React.memo(({ data, mean }) => {
  return (
    <div className="visualization-container">
      <h2 className="section-title">Interactive Mean Calculation</h2>
      <div className="chart-wrapper">
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
            <XAxis dataKey="name" tick={{ fontSize: 12, fill: '#333' }} />
            <YAxis tick={{ fontSize: 12, fill: '#333' }} />
            <Tooltip
              contentStyle={{
                backgroundColor: '#f8f9fa',
                border: '1px solid #dee2e6',
              }}
              labelStyle={{ fontWeight: 'bold', color: '#495057' }}
            />
            <Bar dataKey="value" fill="#4299e1">
              <LabelList
                dataKey="value"
                position="top"
                fill="#2b6cb0"
                fontWeight="bold"
              />
            </Bar>
            <ReferenceLine
              y={mean}
              label={`Mean: ${mean}`}
              stroke="#e53e3e"
              strokeDasharray="3 3"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
});

const StepByStep = React.memo(({ data, mean }) => {
  return (
    <div className="step-by-step">
      <h2 className="section-title">Step-by-Step Calculation</h2>
      <ol>
        <li>
          Sum all values:{' '}
          <span className="highlight">
            {data.reduce((sum, item) => sum + item.value, 0)}
          </span>
        </li>
        <li>
          Count the number of values:{' '}
          <span className="highlight">{data.length}</span>
        </li>
        <li>
          Divide the sum by the count:{' '}
          <span className="highlight">
            {data.reduce((sum, item) => sum + item.value, 0)} / {data.length}
          </span>
        </li>
        <li>
          Result: <span className="highlight">{mean}</span>
        </li>
      </ol>
    </div>
  );
});

const Quiz = React.memo(() => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswer, setUserAnswer] = useState('');
  const [feedback, setFeedback] = useState(null);
  const [showExplanation, setShowExplanation] = useState(false);
  const [hasChecked, setHasChecked] = useState(false);

  const questions = useMemo(
    () => [
      {
        question: 'What is the mean of the initial dataset?',
        answer: 20,
        explanation:
          'To calculate the mean, we sum all values (10 + 15 + 20 + 25 + 30 = 100) and divide by the number of values (5). This gives us 100 / 5 = 20.',
        data: [
          { name: 'Value 1', value: 10 },
          { name: 'Value 2', value: 15 },
          { name: 'Value 3', value: 20 },
          { name: 'Value 4', value: 25 },
          { name: 'Value 5', value: 30 },
        ],
        showAnswerLine: true,
      },
      {
        question:
          'If we add the value 40 to the dataset, what would be the new mean?',
        answer: 23.33,
        explanation:
          'First, we add 40 to our dataset. The new sum is 140 (original sum 100 plus 40). We now have 6 values. Dividing the new sum by the new count gives us 140 / 6 ≈ 23.33.',
        data: [
          { name: 'Value 1', value: 10 },
          { name: 'Value 2', value: 15 },
          { name: 'Value 3', value: 20 },
          { name: 'Value 4', value: 25 },
          { name: 'Value 5', value: 30 },
          { name: 'Value 6', value: 40 },
        ],
        showAnswerLine: true,
      },
      {
        question: 'What is the sum of all values in the initial dataset?',
        answer: 100,
        explanation:
          'To find the sum, we add up all the values: 10 + 15 + 20 + 25 + 30 = 100.',
        data: [
          { name: 'Value 1', value: 10 },
          { name: 'Value 2', value: 15 },
          { name: 'Value 3', value: 20 },
          { name: 'Value 4', value: 25 },
          { name: 'Value 5', value: 30 },
        ],
        showAnswerLine: false,
      },
      {
        question: 'How many data points are in the initial dataset?',
        answer: 5,
        explanation:
          'We simply count the number of values in the dataset. The initial dataset contains 5 values.',
        data: [
          { name: 'Value 1', value: 10 },
          { name: 'Value 2', value: 15 },
          { name: 'Value 3', value: 20 },
          { name: 'Value 4', value: 25 },
          { name: 'Value 5', value: 30 },
        ],
        showAnswerLine: false,
      },
      {
        question:
          'If we remove the last value from the initial dataset, what would be the new mean?',
        answer: 17.5,
        explanation:
          'First, we remove the last value (30) from the dataset. The new sum is 70 (original sum minus the removed value). We now have 4 values. Dividing the new sum by the new count gives us 70 / 4 = 17.5.',
        data: [
          { name: 'Value 1', value: 10 },
          { name: 'Value 2', value: 15 },
          { name: 'Value 3', value: 20 },
          { name: 'Value 4', value: 25 },
        ],
        showAnswerLine: true,
      },
    ],
    []
  );

  const handleCheck = () => {
    const currentQuestion = questions[currentQuestionIndex];
    const isCorrect =
      Math.abs(parseFloat(userAnswer) - currentQuestion.answer) < 0.01;
    setFeedback(isCorrect);
    setHasChecked(true);
  };

  const toggleExplanation = () => {
    setShowExplanation(!showExplanation);
  };

  const goToNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      resetQuestion();
    }
  };

  const goToPreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      resetQuestion();
    }
  };

  const resetQuiz = () => {
    setCurrentQuestionIndex(0);
    resetQuestion();
  };

  const resetQuestion = () => {
    setUserAnswer('');
    setFeedback(null);
    setShowExplanation(false);
    setHasChecked(false);
  };

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div className="quiz-section">
      <h2 className="section-title">Quiz</h2>
      <div className="quiz-question">
        <p className="question-text">{currentQuestion.question}</p>
        <div className="quiz-input">
          <input
            type="number"
            value={userAnswer}
            onChange={(e) => setUserAnswer(e.target.value)}
            placeholder="Answer here"
          />
          <button onClick={handleCheck} className="check-button">
            Check
          </button>
          {hasChecked && (
            <button onClick={toggleExplanation} className="why-button">
              Why
            </button>
          )}
        </div>
        <div className="quiz-graph">
          <ResponsiveContainer width="80%" height={250}>
            <BarChart data={currentQuestion.data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill="#4299e1" />
              {feedback !== null && currentQuestion.showAnswerLine && (
                <ReferenceLine
                  y={currentQuestion.answer}
                  stroke="#2c3e50"
                  strokeDasharray="3 3"
                >
                  <label position="insideTopLeft" fill="#2c3e50">
                    Correct Answer
                  </label>
                </ReferenceLine>
              )}
            </BarChart>
          </ResponsiveContainer>
        </div>
        {feedback !== null && (
          <div className={`feedback ${feedback ? 'correct' : 'incorrect'}`}>
            {feedback ? <CheckCircle size={18} /> : <XCircle size={18} />}
            {feedback ? 'Right Answer!' : 'Wrong Answer'}
          </div>
        )}
        {showExplanation && (
          <div className="explanation">
            <h4>Explanation:</h4>
            <p>{currentQuestion.explanation}</p>
          </div>
        )}
        <div className="quiz-navigation">
          <button
            onClick={goToPreviousQuestion}
            disabled={currentQuestionIndex === 0}
            className="nav-button previous"
          >
            <ArrowLeft size={18} /> Previous Question
          </button>
          {currentQuestionIndex < questions.length - 1 ? (
            <button onClick={goToNextQuestion} className="nav-button next">
              Next Question <ArrowRight size={18} />
            </button>
          ) : (
            <button onClick={resetQuiz} className="nav-button reset">
              Restart Quiz <RefreshCw size={18} />
            </button>
          )}
        </div>
      </div>
      <div className="quiz-progress">
        Question {currentQuestionIndex + 1} of {questions.length}
      </div>
    </div>
  );
});

const BestPractices = React.memo(() => {
  return (
    <div className="best-practices">
      <h2 className="section-title">Best Practices and Use Cases</h2>
      <div className="practices-container">
        <div className="good-for">
          <h4>
            <CheckCircle size={18} /> Good For
          </h4>
          <ul>
            <li>Normally distributed data</li>
            <li>Summarizing large datasets</li>
            <li>Continuous data</li>
          </ul>
        </div>
        <div className="not-good-for">
          <h4>
            <XCircle size={18} /> Not Good For
          </h4>
          <ul>
            <li>Datasets with extreme outliers</li>
            <li>Highly skewed distributions</li>
            <li>Ordinal or nominal data</li>
          </ul>
        </div>
      </div>
    </div>
  );
});

const FormulaProof = React.memo(() => {
  const proofSteps = useMemo(
    () => [
      {
        text: "Start with the definition of the mean: The sum of all values divided by the number of values.",
        formula: "\\mu = \\frac{x_1 + x_2 + ... + x_n}{n}",
        highlight: "\\mu = \\color{green}{\\frac{x_1 + x_2 + ... + x_n}{n}}"
      },
      {
        text: "Express this mathematically using summation:",
        formula: "\\mu = \\frac{\\sum_{i=1}^{n} x_i}{n}",
        highlight: "\\mu = \\frac{\\color{green}{\\sum_{i=1}^{n} x_i}}{n}"
      },
      {
        text: "Simplify by factoring the constant:",
        formula: "\\mu = \\frac{1}{n} \\cdot \\sum_{i=1}^{n} x_i",
        highlight: "\\mu = \\color{green}{\\frac{1}{n}} \\cdot \\sum_{i=1}^{n} x_i"
      },
      {
        text: "This is the final formula for the mean:",
        formula: "\\mu = \\frac{1}{n} \\sum_{i=1}^{n} x_i",
        highlight: "\\mu = \\frac{1}{n} \\color{green}{\\sum_{i=1}^{n} x_i}"
      }
    ],
    []
  );

  return (
    <div className="formula-proof">
      <h2 className="section-title">Mathematical Formula and Proof</h2>
      <div className="formula-proof__main-formula">
        <BlockMath math="\\mu = \frac{1}{n} \sum_{i=1}^{n} x_i" />
      </div>
      <div className="formula-proof__legend">
        <p>
          Where:
          <span className="formula-proof__legend-item"><InlineMath math="\\mu" /> is the mean</span>
          <span className="formula-proof__legend-item"><InlineMath math="n" /> is the number of values</span>
          <span className="formula-proof__legend-item"><InlineMath math="x_i" /> represents each value in the dataset</span>
        </p>
      </div>
      <div className="formula-proof__proof">
        <h4 className="formula-proof__subtitle">Proof:</h4>
        <ol className="formula-proof__steps">
          {proofSteps.map((step, index) => (
            <li key={index} className="formula-proof__step">
              <div className="formula-proof__step-content">
                <p>{step.text}</p>               
                  <div className="highlighted-formula">
                   <BlockMath math={step.highlight} />
                </div>
              </div>
            </li>
          ))}
        </ol>
      </div>
      <div className="formula-proof__conclusion">
        <Info size={18} />
        <p>
          This proof shows that our formula is equivalent to the basic definition of the mean, which is the sum of all values divided by the number of values.
        </p>
      </div>
    </div>
  );
});

const Mean = () => {
  const [data, setData] = useState(initialData);
  const [mean, setMean] = useState(calculateMean(initialData));

  const addDataPoint = useCallback(() => {
    setData((prevData) => {
      const newValue = Math.floor(Math.random() * 50) + 1;
      return [
        ...prevData,
        { name: `Value ${prevData.length + 1}`, value: newValue },
      ];
    });
  }, []);

  const removeDataPoint = useCallback(() => {
    setData((prevData) =>
      prevData.length > 1 ? prevData.slice(0, -1) : prevData
    );
  }, []);

  const resetData = useCallback(() => {
    setData(initialData);
  }, []);

  useEffect(() => {
    setMean(calculateMean(data));
  }, [data]);

  // Memoize child components
  const memoizedVisualization = useMemo(
    () => <MeanVisualization data={data} mean={mean} />,
    [data, mean]
  );
  const memoizedStepByStep = useMemo(
    () => <StepByStep data={data} mean={mean} />,
    [data, mean]
  );
  const memoizedQuiz = useMemo(() => <Quiz />, []);
  const memoizedBestPractices = useMemo(() => <BestPractices />, []);
  const memoizedFormulaProof = useMemo(() => <FormulaProof />, []);

  // Ensure all components are rendered
  return (
    <div className="mean-container">
      <h1 className="function-title">Mean (E(X) or μ)</h1>
      <p className="function-description">
        The mean is a central tendency measure that gives the arithmetic average
        of a dataset. It is calculated by summing all data points and dividing
        by the number of points.
      </p>

      {memoizedVisualization}

      <div className="controls">
        <button onClick={addDataPoint}>
          <Plus size={18} /> Add Data Point
        </button>
        <button onClick={removeDataPoint}>
          <Minus size={18} /> Remove Data Point
        </button>
        <button onClick={resetData}>
          <RefreshCw size={18} /> Reset Data
        </button>
      </div>

      {memoizedStepByStep}
      {memoizedQuiz}
      {memoizedBestPractices}

      <div className="io-explanation">
        <h2 className="section-title">Input and Output</h2>
        <div className="io-container">
          <div className="input">
            <h4>Input</h4>
            <p>A set of numerical values (x₁, x₂, ..., xₙ)</p>
          </div>
          <ArrowRight size={24} className="io-arrow" />
          <div className="output">
            <h4>Output</h4>
            <p>
              The arithmetic average, a single number representing the dataset's
              center.
            </p>
          </div>
        </div>
      </div>

      <div className="real-world-application">
        <h2 className="section-title">Real-World Application</h2>
        <p>The mean is widely used in various fields:</p>
        <ul>
          <li>In finance, to calculate average stock prices or returns</li>
          <li>In education, to determine average test scores</li>
          <li>In meteorology, to find average temperatures</li>
          <li>In manufacturing, to measure average production times</li>
        </ul>
      </div>

      {memoizedFormulaProof}
    </div>
  );
};

export default Mean;
