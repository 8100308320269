// src/App.js
import React, { useState, useMemo } from 'react';
import './App.css';
import ErrorBoundary from './ErrorBoundary';
import Mean from './functions/Mean';
import Median from './functions/Median';
import Mode from './functions/Mode';
import Range from './functions/Range';
import Variance from './functions/Variance';
import StandardDeviation from './functions/StandardDeviation';
import Covariance from './functions/Covariance';
import CorrelationCoefficient from './functions/CorrelationCoefficient';
import ProbabilityMassFunction from './functions/ProbabilityMassFunction';
import ProbabilityDensityFunction from './functions/ProbabilityDensityFunction';
import CumulativeDistributionFunction from './functions/CumulativeDistributionFunction';
import ConditionalProbability from './functions/ConditionalProbability';
import JointProbabilityDistribution from './functions/JointProbabilityDistribution';
import MarginalDistribution from './functions/MarginalDistribution';
import ExpectationOfFunction from './functions/ExpectationOfFunction';
import TotalProbability from './functions/TotalProbability';
import BayesTheorem from './functions/BayesTheorem';
import ChebyshevInequality from './functions/ChebyshevInequality';

const App = () => {
  const [currentFunction, setCurrentFunction] = useState(() => Mean);

  const functions = useMemo(() => [
    Mean, Median, Mode, Range, Variance, StandardDeviation,
    Covariance, CorrelationCoefficient, ProbabilityMassFunction,
    ProbabilityDensityFunction, CumulativeDistributionFunction,
    ConditionalProbability, JointProbabilityDistribution,
    MarginalDistribution, ExpectationOfFunction, TotalProbability,
    BayesTheorem, ChebyshevInequality,
  ], []);

  const renderCurrentFunction = useMemo(() => {
    console.log('Rendering current function:', currentFunction.name);
    return <ErrorBoundary>{React.createElement(currentFunction)}</ErrorBoundary>;
  }, [currentFunction]);

  return (
    <div className="app-container">
      <nav className="top-menu">
        <div className="menu-header">
          <div className="app-name">
            📊
            <span>Statistical Functions</span>
          </div>
          <div className="menu-items">
            {functions.map((Func, index) => (
              <button
                key={index}
                className={`menu-item ${currentFunction === Func ? 'active' : ''}`}
                onClick={() => setCurrentFunction(() => Func)}
              >
                {Func.name || `Function ${index + 1}`}
              </button>
            ))}
          </div>
        </div>
      </nav>

      <main className="main-content">
        {renderCurrentFunction}
      </main>

      <footer className="footer">
        <div className="footer-content">
          <div className="footer-left">
            © {new Date().getFullYear()} Gustav Bok
          </div>
          <div className="footer-right">
            <a
              href="https://www.buymeacoffee.com/gubok"
              target="_blank"
              rel="noopener noreferrer"
              className="bmc-button"
            >
              <img
                src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
                alt="Buy me a coffee"
                style={{ height: '40px', width: 'auto' }}
              />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default App;